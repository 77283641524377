.ActionModal {
	max-height: 500px;
	background-color: #fff;
	border-radius: 20px;
	box-shadow: 0 2px 15px rgba(0, 0, 0, 0.5);
	display: flex;
	flex-flow: column;
	padding-block: 40px;

	&__Header,
	&__Footer {
		height: 80px;
		flex: none;
		padding-left: 40px;
		padding-right: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__Footer {
		grid-gap: 10px;

		.Button {
			width: 114px;
		}
	}

	&__Body {
		flex: auto;
		overflow: auto;
		max-width: 60%;
		align-self: center;
		line-height: 21px;
	}

	&__Title {
		color: #009de0;
		font-size: 30px;
		letter-spacing: -2px;
		font-weight: 400;

		strong {
			font-weight: 500;
		}
	}
}

.ModalOverlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.7);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
}
