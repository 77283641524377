.FileTreeTip {
	&__Title {
		font-size: 16px;
		font-weight: 600;
	}

	&__Content {
		&__Row {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 10px;
			gap: 10px;

			&__Key {
				font-weight: 600;
			}

			&__Value {
				font-weight: 400;
			}
		}
	}
}
