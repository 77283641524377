.Search {
	position: relative;

	& > input {
		background-color: rgb(62, 108, 126, 0.3);
		border: 1px solid rgb(62, 108, 126, 0.3);
		height: 40px;
		min-width: 250px;
		border-radius: 20px;
		outline: none;
		border: none;
		width: 100%;
		padding-left: 44px;
		font-size: 15px;
		letter-spacing: -0.5px;
		color: rgb(255, 255, 255, 0.7);
		transition: all 200ms ease-out;

		&::placeholder {
			color: currentColor;
		}

		&:active,
		&:focus {
			color: transparentize($color: #000000, $amount: 0.3);
			background-color: #fff;
			border: 1px solid #d4e0e6;
			box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.07);

			& ~ .Search__Icon {
				color: transparentize($color: #000000, $amount: 0.3);
			}
		}
	}

	&__Icon {
		position: absolute;
		left: 12px;
		top: 0;
		bottom: 0;
		color: #fff;
		display: flex;
		align-items: center;
	}
}
