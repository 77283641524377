@import '../../Core/scss/u.scss';

.AnalysisExplorer {
	overflow: hidden;
	height: 100%;
	background-color: #002432;
	display: grid;
	grid-template-columns: 1fr 0px;

	& > .Configurator {
		position: relative;
		overflow: hidden;
		@include scrollbar;
	}

	&__ResultsTable {
		margin-left: 30px;
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
	}
}
