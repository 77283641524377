@import '../../Core/scss/u.scss';

.Trends {
	overflow: hidden;
	height: 100%;
	background-color: #002432;
	display: grid;
	grid-template-columns: 1fr 0px;

	& > .Configurator {
		position: relative;
		overflow: hidden;
		@include scrollbar;
	}

	&__ShowQuickReference {
		grid-template-columns: minmax(700px, 1fr) minmax(0px, 319px);

		& > .Trends__QuickReference {
			width: 100%;
			background-color: #002433;
		}
	}
}
