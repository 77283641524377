@import '../../Core/scss/t.scss';

.DashboardCard {
	display: grid;
	grid-template-rows: minmax(0px, 122px) 1fr;
	gap: 1px;

	& > .Card {
		height: fit-content;
	}

	&__MenuIcon {
		margin-top: -10px; // Position based on inner icon, not the hover state
		margin-bottom: -10px; // Position based on inner icon, not the hover state
		display: flex;
		align-items: center;
		justify-content: center;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		color: rgba(255, 255, 255, 0.25);
		border: 1px solid currentColor;
		flex: none;
		transition: border-color $t-hover;
		cursor: pointer;

		&:hover,
		&.isOpen {
			color: #fff;
			border-color: currentColor;
		}

		.Icon {
			position: relative;
			top: -1px;
			color: currentColor;
		}
	}

	& > .Card {
		transition: background-color 150ms ease-out;
	}

	&__WithHover:not([data-screen='fullscreen']) {
		& > .Card:first-child:hover {
			cursor: pointer;
			background-color: lighten(#00384f, 4%);
		}
	}

	&[data-screen='fullscreen'] > .Card:hover {
		background-color: #bada55;
	}

	&[data-size='extended'] {
		& > .Card__Theme-dark {
			display: block;
		}
	}

	&[data-size='fullscreen'] {
		flex-direction: row;
		display: flex;
		gap: 0px;
		height: 100%;
		max-height: 158px;

		& .LineChart {
			padding-top: 0rem;
			padding-bottom: 0.4rem;
		}

		& > div {
			height: 100%;
			width: 50%;
		}

		& .LineChart svg {
			background-color: #00384f !important;
		}
	}

	&__Grid {
		display: grid;
		grid-template-columns: minmax(0, 74px) minmax(0, 1fr);
		gap: 1rem;
		width: 100%;
		height: 100%;
	}

	&__Main {
		display: grid;
		grid-template-rows: repeat(2, minmax(0, 1fr));
		grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
		gap: 4px;
		position: relative;
		color: #fff;

		&[data-size='default'] {
			& .DashboardCard__Title {
				grid-column: 1;
				place-self: end start;
				grid-row: 1;

				&Action {
					display: none;
				}
			}

			& .DashboardCard__Value {
				place-self: end end;
				grid-column: 2;
				grid-row: 1;
			}

			& .DashboardCard__Progress {
				grid-row: 2;
				grid-column: 1/3;
				place-items: start center;
			}
		}

		&[data-size='extended'] {
			& .DashboardCard__Title {
				grid-column: 1;
				grid-row: 1/3;
				place-self: center start;

				&Action {
					display: block;
				}
			}

			& .DashboardCard__Value {
				place-self: end end;
			}

			& .DashboardCard__Progress {
				grid-column: 2;
			}
		}

		&[data-size='fullscreen'] {
			& .DashboardCard__Title {
				grid-column: 1;
				grid-row: 1/3;
				place-self: center start;

				&Action {
					display: none;
				}
			}

			& .DashboardCard__Value {
				place-self: end end;
			}

			& .DashboardCard__Progress {
				grid-column: 2;
			}
		}
	}

	&__Value {
		font-size: 1rem;
		opacity: 0.7;
	}

	&__Progress {
		display: flex;
		flex-direction: column;
		gap: 4px;
	}

	&__Title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 16px;
		letter-spacing: -0.16px;
		line-height: 19px;
		color: #fff;
		max-width: 100%;

		&Text {
			font-size: 1rem;
			margin-right: 6px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			text-transform: capitalize;
		}

		&Action {
			margin-left: 5px;
			transition: transform 150ms ease-in;
			transform: rotate(-90deg);

			&--Expanded {
				transform: rotate(0deg);
			}
		}
	}

	&__Selectors {
		position: absolute;
		z-index: 4;
		display: flex;
		gap: 6px;
		align-items: center;
		margin-top: 7px;

		&-Label {
			max-width: 15ch;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	&__Indicators {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}
}

.DashboardCardStatus {
	width: 74px;
	height: 74px;
	place-self: center;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	border-radius: 6px;
	color: #fff;

	&__Status-default {
		background-color: var(--dashboard-green);
	}
	&__Status-alert {
		background-color: var(--dashboard-yellow);
	}
	&__Status-warning {
		background-color: var(--dashboard-orange);
	}
	&__Status-critical {
		background-color: var(--dashboard-red);
	}

	&__Alert-icon {
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(50%, -50%);
	}
}
