.SlideInOverlay {
	&__Content {
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		z-index: 2;
		width: 100%;
	}

	&__Background {
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		z-index: 1;
		background-color: transparentize(#002433, 0.3);
	}
}
