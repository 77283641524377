@keyframes pulsating {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.6;
	}
	100% {
		opacity: 1;
	}
}

.RealtimeIndicator {
	border: 1px solid rgba(255, 255, 255, 0.6);
	border-radius: 3px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
	padding: 3px 7px;

	&__Dot {
		background-color: #06bb75;
		width: 7px;
		height: 7px;
		border-radius: 50%;

		&--Pulsating {
			animation: pulsating infinite 1500ms linear;
		}

		&--Offline {
			background-color: #e00039;
		}
	}

	&__Title {
		user-select: none;
		color: #fff;
		font-size: 11px;
		text-transform: uppercase;
		letter-spacing: -0.73px;
		vertical-align: baseline;
	}
}
