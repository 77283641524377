.DragOverScrollSection {
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	pointer-events: none;

	&__Left,
	&__Right {
		background-color: transparent;
		width: 75px;
		height: 100%;
		position: absolute;
		top: 0;
		pointer-events: all;
	}

	&__Left {
		left: 0;
	}

	&__Right {
		right: 0;
	}
}
