.SRSFileStructureBody {
	height: 100%;
	&__Navigation {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
		height: 50px;
		border-bottom: 1px solid #d4e0e6;
		transition: background-color 0.2s ease-in-out,
			border-bottom 0.2s ease-in-out;
		margin-top: 1px;

		&:hover {
			cursor: pointer;
			background-color: darken(#ffffff, 2%);
			border-bottom: 1px solid #009de0;
		}

		&__Path {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			margin-left: 2px;
			height: 100%;
			overflow: hidden;
			letter-spacing: -0.5px;

			&__prevPath {
				color: #ccd3d6;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 5px;
				height: 100%;

				&__Content {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					gap: 5px;

					&__FirstElement {
						color: #8a9092;
						transition: color 0.2s ease-in-out;

						&:hover {
							color: darken(#8a9092, 15%);
							cursor: pointer;
						}
					}

					&:last-child {
						padding-right: 5px;
					}
				}
			}
			&__currentPath {
				color: #002433;
			}
		}

		&__BackButton {
			margin-left: 20px;
			//turn around 180 degrees
			transform: rotate(180deg);
			color: #429dda;

			&:hover {
				color: darken(#429dda, 5%);
				cursor: pointer;
			}
		}
	}

	&__AddButton {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 50px;
		width: 170px;
		padding: 0 40px;
		font-weight: 500;
		letter-spacing: -0.8px;

		.IconButton__Text {
			font-size: 14px;
		}
	}

	&__Add {
		// These numbers are slightly odd, but it's to match the design when the transition is applied
		margin-left: 41px;
		margin-right: 41px;
		margin-top: 17px;
	}

	&__Loading {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		transform: translate(0, 150%);
	}

	&__Node {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: #f6f7f8;
		height: 50px;
		padding: 0 40px;

		&__Name {
			letter-spacing: -1.13px;
			color: #002433;
		}

		&--Folder {
			cursor: pointer;
			transition: background-color 0.2s ease-in-out;
			background-color: #ffffff;

			&:hover {
				background-color: darken(#ffffff, 2%);
			}
		}

		&--File {
			transition: background-color 0.2s ease-in-out;
		}

		&__Controls {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 15px;
			height: 100%;

			&__ConditionContainer {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 12px;
			}

			&__Menu {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100%;

				&:hover {
					cursor: pointer;
				}

				&__ThreeDotsH {
					display: flex;
					justify-content: center;
					align-items: center;
					color: #979797;
					height: 100%;

					&:hover {
						color: darken(#009de0, 30%);
					}
				}
			}

			&__Info {
				color: #429dda;

				&:hover {
					color: darken(#429dda, 5%);
					cursor: help;
				}
			}

			&__MetaData {
				position: absolute;
				top: 10px;
				right: 10px;
				color: #429dda;
				z-index: 100;
			}

			&__Download {
				color: #429dda;
				transition: color 0.2s ease-in-out;

				&:hover {
					color: darken(#429dda, 15%);
					cursor: pointer;
				}
			}

			&__CaretRight {
				margin-top: 1px;
				color: #429dda;
			}
		}
	}
}
