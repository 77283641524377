@import '../../Core/scss/t.scss';

.DashboardHeader {
	width: 100%;
	height: 110px;
	display: flex;
	align-items: center;
	box-shadow: 6px 2px 12px 0 rgba(0, 0, 0, 0.5);

	&__Title {
		padding-left: 46px;
		color: #fff;
		font-size: 20px;
		line-height: 24px;
		letter-spacing: -1.33px;
	}

	&__Realtime {
		margin-left: 13px;
	}

	&__Actions {
		margin-left: auto;
		justify-self: flex-end;
		padding-right: 38px;
		display: flex;
		color: #009de0;
		gap: 15px;
	}

	&__Fullscreen {
		transition: color $t-hover;

		.Icon {
			cursor: pointer;
		}

		&:hover {
			color: mix(#009de0, #fff, 50%);
		}
	}

	&__QuickReference {
		transition: color $t-hover;

		.Icon {
			cursor: pointer;
		}

		&:hover {
			color: mix(#009de0, #fff, 50%);
		}

		&--Open {
			color: rgba(255, 255, 255, 0.25);

			.Icon {
				cursor: not-allowed;
			}

			&:hover {
				color: rgba(255, 255, 255, 0.25);
			}
		}
	}
}
