$inactiveColor: transparentize(#002433, 0.7);
$activeColor: rgb(0, 36, 51, 0.5);
$activeHoverColor: rgb(0, 36, 51, 1);

.AdminBreadcrumb {
	display: flex;
	align-items: center;

	&__Base {
		display: flex;
		align-items: center;
		gap: 4px;
		color: $activeColor;
	}

	&__Icon {
		width: 30px;
		height: 30px;
		border: 1px solid $activeColor;
		border-radius: 50%;
		position: relative;
		transition: all 200ms ease-out;
		order: -1;

		& > .Icon {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -55%);
		}

		&--Last {
			color: $inactiveColor;
			border-color: $inactiveColor;
			cursor: default;
		}
	}

	&__Section {
		display: flex;
		align-items: center;
	}

	& > a {
		text-decoration: none;
	}

	&__Text {
		font-size: 1rem;
		color: $activeColor;
		transition: all 200ms ease-out;

		&:hover {
			text-decoration: underline;
			color: $activeHoverColor;
		}

		&:hover ~ .AdminBreadcrumb__Icon {
			background-color: #002433;
			border-color: #002433;
			color: #eceff0;
		}

		&--Last {
			color: $inactiveColor;
			cursor: default;

			&:hover {
				text-decoration: none;
				color: $inactiveColor;
			}

			&:hover ~ .AdminBreadcrumb__Icon {
				background-color: #eceff0;
				color: rgba(0, 36, 51, 0.4);
				border-color: rgba(0, 36, 51, 0.4);
			}
		}
	}

	&__Divider {
		&:hover {
			text-decoration: none;
		}
	}
}
