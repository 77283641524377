@import '../../Core/scss/u.scss';
@import '../../Core/scss/t.scss';

.DimensionChooser {
	$padding-v: 40px;
	$greyColor: #002433;

	&__CategoryName {
		padding-left: $padding-v;
		padding-right: $padding-v;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-weight: bold;
		font-size: 15px;
		color: #002433;
		border-bottom: 1px solid #d4e0e6;
		cursor: pointer;
	}

	&__CategoryDimensions {
		background-color: #eceff0;
		border-bottom: 1px solid #d4e0e6;
	}

	&__Dimension {
		margin-left: $padding-v;
		padding-right: $padding-v;
		height: 40px;
		align-items: center;
		display: flex;
		cursor: pointer;

		& + & {
			border-top: 1px solid #d4e0e6;
		}
	}

	&__DimensionName {
		@include elipsis;

		color: #000;
		font-size: 15px;
		font-weight: 500;
		transition: color $t-hover;

		@at-root .DimensionChooser__Dimension.isSelected & {
			color: transparentize($greyColor, 0.6);
		}

		@at-root .DimensionChooser__Dimension.isSelected:hover & {
			color: transparentize($greyColor, 0.4);
		}
	}

	&__DimensionAdd,
	&__DimensionRemove {
		margin-left: auto;
		padding-left: 20px; // Distance to any "elipsis" names on the left
	}

	&__DimensionAdd .IconButton {
		$color: #009de0;

		color: $color;

		@at-root .DimensionChooser__Dimension:hover & {
			color: darken($color, 30%);
		}
	}

	&__DimensionRemove .IconButton {
		color: transparentize($greyColor, 0.6);

		@at-root .DimensionChooser__Dimension:hover & {
			color: transparentize($greyColor, 0.3);
		}
	}

	&__FooterButtons {
		display: flex;
		justify-content: space-between;
		width: 100%;

		&__SaveCancel {
			display: flex;
			justify-content: flex-end;
			gap: 10px;
		}

		&__Clear {
			outline: transparent;
			background-color: transparent;
			border: none;
			font-family: 'Gotham Rounded A', 'Gotham Rounded B', sans-serif;
			transition: color 150ms ease-in-out;

			&--Disabled {
				color: #00263670;
			}

			&--Enabled {
				color: #009de0;
				cursor: pointer;

				&:hover {
					color: darken(#009de0, 5%);
				}
			}
		}
	}
}
