.SRSEdit {
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-bottom: 20px;
	margin-top: 10px;
	margin-right: 40px;
	margin-left: 40px;

	&__Title {
		font-size: 14px;
		font-weight: 500;
		letter-spacing: -0.8px;
		color: #809199;
	}

	&__TextboxContainer {
		display: flex;
		flex-direction: column;
		gap: 6px;
	}

	&__Buttons {
		display: flex;
		gap: 10px;
		justify-content: flex-end;
		transition: all 0.2s ease;
		margin-top: 5px;

		&--Loading {
			.Button {
				background-color: #f5f5f5;
				color: #809199;
				cursor: not-allowed;
			}
		}
	}
}
