.AdminAccountForm {
	padding: 0 48px;
	color: #fff;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.6rem;

	&__Actions {
		display: flex;
		flex-direction: column;

		& > .Button {
			padding: 0;
		}
	}

	& > .Button:last-of-type {
		position: fixed;
		top: 40px;
		right: 48px;
	}
}
