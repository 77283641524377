.CalendarHeader {
	display: block;
	position: relative;

	&__Month {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-inline: 45px;
	}

	&__Action {
		cursor: pointer;
		border-radius: 50%;
		width: 31px;
		height: 31px;
		transition: background-color 150ms ease-out;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid #fff;

		&--PrevMonth {
			transform-origin: 50%;
			transform: rotate(180deg) translateY(-1px);

			& > .Icon {
				transform: translate(0.5px, -0.5px);
			}
		}

		&:hover {
			border-color: #009de0;
			color: #009de0;
		}
	}

	& .react-datepicker {
		display: block;
	}

	& .react-datepicker__current-month {
		color: #fff;
		text-transform: capitalize;
		font-size: 14px;
	}
}

.react-datepicker__header {
	background-color: #00384f;
	border-bottom: 1px solid #00384f;
	color: #fff;
	padding-bottom: 17px;
	padding-top: 32px;
}

.react-datepicker__day-names {
	display: none;
}

.react-datepicker__navigation-icon--next {
	transform: rotate(180deg);
}

.react-datepicker__navigation-icon {
	color: #fff;

	&::before {
		display: none;
	}
}
