.LoginForm {
	$brand-color: #009de0;
	position: relative;

	&__Form {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	&__Actions {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 10px;
		margin-top: 8px;

		& .Button {
			width: 100%;
		}
	}

	&__Login > .Button {
		font-size: 14px;
	}

	&__ForgotPassword > .Button {
		font-size: 12px;
	}

	&__Error {
		opacity: 0;
		font-size: 14px;
		margin-top: 15px;
		margin-bottom: 25px;
		text-align: center;
		color: #fff;
		border-radius: 6px;
		padding: 10px;
		transition: opacity 100ms ease-out;

		&--Active {
			opacity: 1;
		}
	}
}
