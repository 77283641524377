@import '../../../Core/scss/t.scss';

.ManageSRSButton {
	position: absolute;
	bottom: 0;
	right: 0;
	padding: 30px;
	z-index: 10;
	width: 205px;
	font-weight: 400;

	.IconButton {
		background-color: rgba(255, 255, 255, 0.1);
		border-radius: 6px;
		height: 40px;
		transition: background-color $t-hover;

		&:hover {
			background-color: rgba(255, 255, 255, 0.15);
		}

		&__Text {
			color: rgba(255, 255, 255, 0.6);
		}

		.Icon {
			color: #a3b0b5;
		}
	}
}

.Modal {
	&__Body {
		overflow: scroll;
		overflow-x: hidden;
	}
}

.SRSHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	width: 100%;

	&__Title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 6px; //This is the "space"
		color: #429dda;
		font-size: 22px;
		letter-spacing: -1.33px;

		&__Main {
			font-weight: 500;
		}

		&__Section {
			font-weight: 400;
			overflow: hidden;
		}
	}

	&__RightContainer {
		display: flex;
		align-items: center;
		gap: 25px;

		&__UploadButton {
			.IconButton {
				&__Text {
					margin-right: 5px;
					font-weight: 400;
					user-select: none;
					margin-right: 0px;
				}
			}
		}

		&__CloseButton {
			color: #009de0;
			transition: color $t-hover;
			height: 23px; // The button is only 22px, but is not entirely centered (in the svg), so it is cut off. 1 pixel extra is added to the height to fix this.

			.Icon {
				cursor: pointer;
			}

			&:hover {
				color: mix(#009de0, #000, 90%);
			}

			&--Open {
				color: rgba(255, 255, 255, 0.25);

				.Icon {
					cursor: not-allowed;
				}

				&:hover {
					color: rgba(255, 255, 255, 0.25);
				}
			}
		}
	}
}

.Body {
	&__UploadArea {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		background-color: #fbfbfc;
		border-bottom: #d4e0e6 1px solid;
		cursor: pointer;
		transition: background-color $t-hover;
		box-sizing: border-box;
		line-height: 17px;

		&-Drag-Over {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			background-color: #d8e8ef;
			border-bottom: #d4e0e6 1px solid;
			cursor: pointer;
			transition: background-color $t-hover;
			box-sizing: border-box;
			line-height: 17px;
		}

		&__Content {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 140px;
			width: 95%;
			gap: 15px;
			border-radius: 20px;
			transition: all $t-hover;
			border: 2px dashed transparent;
			box-sizing: border-box;

			&-Drag-Over {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				height: 140px;
				width: 95%;
				gap: 15px;
				border-radius: 20px;
				transition: all $t-hover;
				border: 2px dashed #009de0;
				box-sizing: border-box;
			}

			&__Icon {
				font-size: 50px;
				color: #009de0;
			}

			&__Text {
				width: 260px;
				font-size: 14px;
				color: #809199;
				text-align: center;
				font-weight: 400;
				transition: color $t-hover;
				box-sizing: border-box;

				&-Drag-Over {
					width: 260px;
					font-size: 14px;
					text-align: center;
					font-weight: 400;
					transition: color $t-hover;
					color: #009de0;
					box-sizing: border-box;
				}

				&__Folder {
					font-weight: 500;
					color: #596469;
					transition: color 0.2s ease-in-out;

					&:hover {
						color: #009de0;
					}
				}
			}
		}
	}
}
