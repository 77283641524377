.SRSUpload {
	height: 50px;
	width: 100%;
	padding: 0 40px;

	display: flex;
	justify-content: space-between;
	align-items: center;

	background-color: #f6f7f8;
	border-bottom: 1px solid #d4e0e6;

	&__fileName {
		color: #a5b2b7;
		font-size: 15px;
	}

	&__progressContainer {
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;

		&__uploadText {
			color: #809199;
			font-size: 11px;
			margin-right: 4px;

			&--error {
				font-size: 11px;
				margin-right: 4px;
				color: #e00039;
			}
		}

		&__progressBarContainer {
			height: 4px;
			width: 80px;
			background-color: #d2d7db;
			border-radius: 2px;

			&__progressBar {
				height: 100%;
				background-color: #009de0;
				border-radius: 2px;

				&--error {
					height: 100%;
					border-radius: 2px;
					background-color: #e00039;
				}
			}
		}

		&__progress {
			color: #809199;
			font-size: 11px;
			width: 30px;
			display: flex;
			justify-content: flex-end;
		}

		&__errorDismiss {
			color: #809199;
			transition: color 0.2s ease-in-out;

			&:hover {
				cursor: pointer;
				color: darken(#809199, 15%);
			}
		}
	}
}
