.AdminAccountEdit {
	height: 100vh;
	background-color: #002433;
	display: grid;
	grid-template-rows: 150px 1fr;
	color: #fff;

	&__Error {
		width: 100%;
		padding: 0px 48px;
		display: flex;
		align-items: center;

		&Message {
			padding: 10px;
			border-radius: 6px;
			text-align: center;
			width: 100%;
		}
	}

	&__Top {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		padding: 40px 48px 0px 48px;
	}
}
