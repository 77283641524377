@import '../../scss/t.scss';

.IconButton {
	display: flex;
	align-items: center;
	cursor: pointer;
	column-gap: 4px;

	&__Text {
		font-size: 13px;
		margin-top: -1px; // Optical alignment
	}

	&__Text,
	&__Icon {
		transition: color $t-hover;
	}
}

.IconButton--align {
	&-right {
		flex-direction: row;
	}

	&-left {
		flex-direction: row-reverse;
	}
}

.IconButton--theme {
	&-blue-grey {
		.IconButton__Text {
			color: transparentize(#fff, 0.4);
		}
		.IconButton__Icon {
			color: #00a0dd;
		}

		&:hover {
			.IconButton__Text {
				color: #fff;
			}
		}
	}

	&-blue-text-transparent {
		.IconButton__Text {
			color: #00a0dd;
		}
		.IconButton__Icon {
			color: #00a0dd;
		}

		&:hover {
			.IconButton__Text {
				color: darken(#00a0dd, 10%);
			}
			.IconButton__Icon {
				color: darken(#00a0dd, 10%);
			}
		}
	}

	&-blue-white-rounded {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		background-color: #00a0dd;
		border-radius: 24px;
		border: none;
		padding: 10px;
		height: 100%;
		width: 100%;
		.IconButton__Text {
			color: #f5fafc;
		}
		.IconButton__Icon {
			color: #f5fafc;
			margin-right: 2px;
		}
	}

	&-greyed-out-transparent {
		.IconButton__Text {
			color: #809199;
		}
		.IconButton__Icon {
			color: #809199;
		}
	}

	&-none {
		color: red; // Fallback color

		.IconButton__Text,
		.IconButton__Icon {
			color: currentColor;
		}
	}
}
