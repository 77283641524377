@import '../../scss/t.scss';

.ListItem {
	background-color: #fff;
	display: grid;
	padding: 22px 29px;
	gap: 6px;
	align-items: center;
	font-size: 15px;

	&__HandleClick:hover {
		cursor: pointer;
		background-color: darken($color: #fff, $amount: 3);
	}

	& > * {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&__Action {
		place-self: end;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 30px;
		height: 100%;
		border-radius: 50%;
		flex: none;
		transition: border-color $t-hover;
		cursor: pointer;
		grid-column-end: -1;

		&:hover,
		&.isOpen {
			border-color: currentColor;
		}

		.Icon {
			position: relative;
			top: -1px;
			color: currentColor;
		}
	}
}
