.SRSRevisionBody {
	&__Revisions {
		&__Container {
			border-bottom: 1px solid #d4e0e6;
			&__Entry {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0 40px;
				height: 50px;
				//
				cursor: pointer;
				transition: background-color 0.2s;
				font-weight: bold;

				&:hover {
					background-color: darken(white, 2%);
				}

				&__Name {
					font-size: 16px;
				}

				&__Controls {
					display: flex;
					align-items: center;
					gap: 10px;
					height: 100%;

					&__Menu {
						display: flex;
						justify-content: center;
						align-items: center;
						height: 100%;

						&:hover {
							cursor: pointer;
						}

						&__ThreeDotsH {
							display: flex;
							justify-content: center;
							align-items: center;
							color: #979797;
							height: 100%;
							margin-right: 5px;

							&:hover {
								color: darken(#009de0, 30%);
							}
						}
					}

					&__Info {
						color: #429dda;
						margin-right: 1px;

						&:hover {
							cursor: help;
						}
					}

					&__CaretRight {
						margin-left: 8px;
						margin-top: 1px;
						color: #429dda;
					}
				}
			}
		}
	}

	&__AddButton {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 38px;
		height: 50px;
		width: 178px;
		font-weight: 500;
		letter-spacing: -0.8px;

		.IconButton__Text {
			font-size: 14px;
		}
	}

	&__Add {
		// These numbers are slightly odd, but it's to match the design when the transition is applied
		margin-left: 41px;
		margin-right: 41px;
		margin-top: 17px;
	}

	&__Loading {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		transform: translate(0, 150%);
	}
}
