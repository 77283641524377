.ElementType {
	display: flex;
	align-items: center;

	&__Name {
		font-size: 13px;
		font-weight: bold;
		color: #009de0;
		margin-right: 6px;
		margin-top: -1px;
	}
}
