.Configurator {
	position: relative;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background-color: #002432;
	display: flex;
	flex-flow: column;
	color: #fff;
	z-index: 2;

	.ConfiguratorHeader {
		flex-grow: 0;
		user-select: none;
	}

	.ConfiguratorBody {
		flex-grow: 1;
		overflow: auto;
	}
}

.SizeDragContainer {
	top: 40vh;
	left: 3px;
	position: absolute;
	height: 250px;
	width: 24px;
	border-radius: 10px;
	z-index: 1;
	cursor: grab;
	transition: background-color 180ms ease-in-out;

	&:hover {
		background-color: rgba(255, 255, 255, 0.1);

		.SizeDragContainer {
			&__Button {
				&__Shape {
					opacity: 1;
				}
			}
		}
	}

	&:hover:active {
		cursor: grabbing;
		background-color: rgba(255, 255, 255, 0.2);
	}

	&__Button {
		display: flex;
		gap: 2px;
		&__Shape {
			user-select: none;
			transition: opacity 180ms ease-in-out;
			position: relative;
			width: 2px;
			height: 30px;
			top: 106px;
			left: 8px;
			border-radius: 10px;
			opacity: 0.6;
			background-color: #ffffff;
		}
	}
}
