.ElementDetailsHeader {
	padding: 41px 43px 41px 45px;

	&__Details {
		margin-top: 45px;
		display: flex;
		align-items: center;
	}

	&__Identifier {
		color: #ffffff;
		font-size: 30px;
		font-weight: 500;
		line-height: 1;
	}

	&__Type {
		color: #009de0;
		margin-left: auto;
		font-size: 13px;
	}
}
