@use 'sass:math';

@import '../../../Core//scss/t.scss';

.SelectedDivider {
	display: flex;
	align-items: center;
	gap: 6px;
	height: 30px;

	&::after,
	&::before {
		content: '';
		height: 1px;
		flex: auto;
		background-color: rgba(255, 255, 255, 0.25);
	}
}

.SelectedDividerButton {
	$height: 20px;

	user-select: none;
	padding: 0 10px;
	border: 1px solid #405a66;
	height: $height;
	border-radius: math.div($height, 2);
	font-size: 13px;
	font-weight: 400;
	display: flex;
	align-items: center;
	flex: none;
	color: rgba(255, 255, 255, 0.6);
	cursor: pointer;
	transition: background-color $t-hover, color $t-hover;

	&:hover {
		background-color: rgba(255, 255, 255, 0.1);
		color: rgba(255, 255, 255, 0.7);
	}
}
