@import '../../scss/t.scss';

.ConfiguratorTabs {
	&__Tabs {
		display: flex;
	}

	&__Tab {
		$selectionBarHeight: 4px;
		$titleVerticalPadding: 20px;

		flex: auto;
		display: flex;
		justify-items: center;
		padding-top: $titleVerticalPadding;
		padding-bottom: $titleVerticalPadding + $selectionBarHeight;
		font-weight: bold;
		line-height: 1;
		font-size: 14px;
		justify-content: center;
		position: relative;
		color: transparentize(#fff, 0.5);
		transition: color $t-hover;
		cursor: pointer;

		// Selection bar
		&:before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: $selectionBarHeight;
			background-color: transparentize(#fff, 0.8);
			transition: background-color $t-hover;
		}

		&:hover {
			color: transparentize(#fff, 0.3);

			&:before {
				background-color: transparentize(#fff, 0.7);
			}
		}

		&.isSelected {
			color: #fff;

			// Selection bar
			&:before {
				background-color: #009de0;
			}
		}
	}
}
