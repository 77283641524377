.ActiveIndicator {
	display: flex;
	align-items: center;
	gap: 5px;

	&__Dot {
		width: 10px;
		height: 10px;
		border-radius: 3px;
		transform: translateY(-1px);
	}

	&__Text {
		color: transparentize($color: #000000, $amount: 0.6);
	}
}
