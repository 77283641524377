$brand-color: #009de0;

.ToggleButton {
	height: 16px;
	width: 53px;
	border: 1px solid rgba(0, 36, 51, 0.4);
	border-radius: 8px;
	padding: 0px;
	padding-inline: 4px;
	cursor: pointer;
	background-color: #fff;
	transition: 150ms ease-in-out background-color, 150ms ease-in-out border-color;

	&:focus {
		box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
	}

	&:focus-visible {
		outline: none;
	}

	&--Disabled {
		opacity: 0.8;
		cursor: not-allowed;
	}

	&:hover {
		border-color: darken(rgba(0, 36, 51, 0.4), 10%);
		.ToggleButton__Tick {
			background-color: darken(rgba(0, 36, 51, 0.4), 10%);
		}
	}

	&--On {
		border-color: $brand-color;

		&:hover {
			border-color: darken($brand-color, 5%);
			.ToggleButton__Tick {
				background-color: darken($brand-color, 5%);
			}
		}
	}

	&__Tick {
		background-color: rgba(0, 36, 51, 0.4);
		transition: transform 150ms ease-out, background-color 150ms ease-out;
		height: 8px;
		width: 23px;
		border-radius: 4px;
		transform: translateX(0px);
		margin: 0;

		&--On {
			background-color: $brand-color;
			transform: translateX(20px);
		}
	}
}
