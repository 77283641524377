@import '../../scss/t.scss';

.SideMenu {
	width: 110px;
	display: flex;
	flex-flow: column;
	padding-top: 35px;
	padding-bottom: 38px;
	overflow: hidden;
	position: relative;

	// Animated background for transitioning between sidebar and overlay themes
	&:after {
		content: '';
		position: absolute;
		top: 10px;
		bottom: 10px;
		right: 10px;
		left: 10px;
		border-radius: 20px;
		background-color: #002433;
		transition: transform ease-out 700ms;
	}

	// Push all the content in front of the background graphic
	> * {
		z-index: 1;
	}

	&__Logo {
		width: 100%;
		display: flex;
		justify-content: center;
		cursor: pointer;
	}

	&__Buttons {
		margin-top: 97px;
		display: flex;
		flex-flow: column;
		align-items: center;

		.MenuButton + .MenuButton {
			margin-top: 30px;
		}
	}

	&__BottomActions {
		margin-top: auto;
		display: flex;
		flex-flow: column;
		align-items: center;
	}

	&__User {
		$backgroundColor: #1b5275;

		height: 34px;
		width: 34px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		font-size: 12px;
		line-height: 1;
		color: #fff;
		position: relative;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: $backgroundColor;
			border-radius: 6px;
			transition: background-color $t-hover, transform $t-hover;
		}

		&:hover:after {
			background-color: lighten($backgroundColor, 10%);
			transform: scale(1.15);
		}
	}

	&__UserLetter {
		position: relative;
		z-index: 1;
	}

	&__LogOut {
		width: 34px;
		height: 34px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: transparentize(#fff, 0.6);
		cursor: pointer;
		transition: color $t-hover;
		margin-bottom: 23px - 7px;

		&:hover {
			color: transparentize(#fff, 0.2);
		}
	}
}

$overlayWidth: 110 - (2 * 10); // "standard" width + 2 insets of 10 px
$overlapWidth: 110 + (2 * 20); // "standard" width + border radius overlap on each side

.SideMenu--Theme-Overlay {
	&:after {
		transform: scale(1);
	}
}

.SideMenu--Theme-Sidebar {
	&:after {
		transform: scale(calc($overlapWidth / $overlayWidth), 1.1);
	}
}
