.Login {
	$brand-color: #009de0;
	display: grid;
	grid-template-columns: 1fr 500px;
	grid-template-rows: 1fr;
	height: 100vh;
	background-color: #002433;

	&__BackgroundImage {
		flex: auto;
		background-position: center center;
		background-size: cover;
	}

	&__Logo {
		position: absolute;
		top: 58px;
		left: 60px;
	}

	&__Icon {
		margin-bottom: 50px;
	}

	&__Title {
		font-size: 30px;
		color: $brand-color;
		margin-bottom: 20px;
	}

	&__Instruction {
		color: #fff;
		margin-top: 20px;
		line-height: 1.2;
	}

	&__Form {
		background-color: #002433;
		padding: 120px 90px;
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	&__TOS {
		display: flex;
		justify-self: flex-end;
		margin-top: auto;

		& > .Button {
			font-size: 12px;
			width: 110px;
		}
	}

	&__Link {
		color: inherit;
		text-decoration: none;
	}
}
