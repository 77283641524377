.Explorer {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	display: flex;

	&__Model {
		position: relative;
		flex: 1 1 auto;
		width: 0; // Kind of a hack to get the layout to shrink correctly to fill in the left space
		user-select: none; // Disable text selection which bugs out on double click (selecting the headline instead of interacting with the model)
	}

	&__SectionMenu {
		position: absolute;
		top: 35px;
		left: 120px;
		z-index: 1;
	}

	&__Navigation {
		position: absolute;
		bottom: 30px;
		right: 30px;
	}

	&__AnalysisExplorer {
		width: 50%;
		background-color: #002433;
		flex: none;
		position: relative;
		z-index: 1; // Make sure Section Selector overlay is beneath on isLarge
	}

	&__AnalysisExplorer.isSmall {
		width: 620px;
	}

	&__AnalysisExplorer.isLarge {
		width: calc(100% - 110px);
	}
}
