.ProgressBar {
	height: 4px;
	width: 100%;
	border-radius: 2px;
	background-color: rgba(255, 255, 255, 0.15);
	overflow: hidden;
	position: relative;

	&__Bar {
		height: 100%;

		&-default {
			background-color: var(--dashboard-green);
		}

		&-alert {
			background-color: var(--dashboard-yellow);
		}

		&-warning {
			background-color: var(--dashboard-orange);
		}

		&-critical {
			background-color: var(--dashboard-red);
		}
	}
}

.ProgressBarBreakpoint {
	background-color: #00384f;
	height: 100%;
	width: 1px;
	z-index: 2;
	position: absolute;
	top: 0;
	bottom: 0;
}
