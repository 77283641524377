.BackButton {
	display: flex;
	align-items: center;
	gap: 7px;
	cursor: pointer;

	& > .Icon {
		color: #009de0;
	}

	&__Label {
		font-size: 13px;
		font-weight: 500;
		opacity: 0.6;
		letter-spacing: -0.87px;
		line-height: 16px;
	}
}
