.Counter {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	&--Default {
		border-radius: 50%;
		border: 1px solid rgba(255, 255, 255, 0.25);
		background-color: #00384f;
		font-size: 9px;
	}
}
