.Textbox {
	position: relative;

	&__Input,
	&__TextArea {
		border-radius: 4px;
		width: 100%;
		background-color: #eceff0;
		border: 1px #d4e0e6 solid;
		outline: none;
		box-shadow: none;
		transition: border-color 0.2s ease-in-out;
		padding: 14px;
		padding-top: 25px;
		font-weight: normal !important;
		font-size: 16px;
		letter-spacing: -1.33px;

		&:focus {
			border: 1px rgb(0, 36, 51, 0.3) solid;
		}

		&:hover {
			border: 1px rgb(0, 36, 51, 0.3) solid;
		}
	}

	&__Input {
		height: 50px;
	}

	&__TextArea {
		padding-top: 25px;
		min-height: 120px;
		max-height: 120px;
		max-width: 100%;
		min-width: 100%;
		resize: none;
	}

	&__Title {
		font-family: 'Gotham Rounded A', 'Gotham Rounded B';
		position: absolute;
		top: 18px;
		left: 0;
		transition: all 0.2s ease-in-out;
		transform-origin: left bottom;
		pointer-events: none;
		font-size: 12px;
		color: #809199;
		padding: 0 14px;
		font-weight: 500;
	}

	&.is-active {
		.Textbox__Title {
			top: 8px;
			color: #809199;
		}
	}
}
