.ButtonCounter {
	position: relative;

	&__Counter {
		position: absolute;
		top: -4px;
		right: -4px;
		width: 16px;
		height: 16px;
	}
}
