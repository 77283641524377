.IndicatorDot {
	border-radius: 50%;

	&__Status-default {
		background-color: var(--dashboard-green);
	}
	&__Status-alert {
		background-color: var(--dashboard-yellow);
	}
	&__Status-warning {
		background-color: var(--dashboard-orange);
	}
	&__Status-critical {
		background-color: var(--dashboard-red);
	}
}
