@import '../../scss/t.scss';

.ConfiguratorHeader {
	background-color: #002432;

	position: relative; // Shadow

	// Shadow
	&:after {
		content: '';
		top: 100%;
		left: 0;
		right: 0;
		height: 14px;
		position: absolute;
		pointer-events: none;
		z-index: 3;
		background: linear-gradient(
			transparentize(#000, 0.6),
			transparentize(#000, 1)
		);
	}

	&__Header {
		display: flex;
		padding-top: 43px;
		padding-bottom: 43px;
		padding-left: 48px;
		padding-right: 44px;
		align-items: center;
	}

	&__Title {
		color: #ffffff;
		font-size: 20px;
		height: 20px;
		font-weight: bold;
		line-height: 1;
	}

	&__Icons {
		display: flex;
		flex-flow: row;
		grid-gap: 15px;
		margin-left: auto;
	}

	&__Close,
	&__Expand,
	&__QuickReference {
		color: #009de0;
		transition: color $t-hover;

		.Icon {
			cursor: pointer;
		}

		&:hover {
			color: mix(#009de0, #fff, 50%);
		}

		&--Open {
			color: rgba(255, 255, 255, 0.25);

			.Icon {
				cursor: not-allowed;
			}

			&:hover {
				color: rgba(255, 255, 255, 0.25);
			}
		}
	}
}

.ConfiguratorHeader.hasChildren {
	.ConfiguratorHeader__Header {
		padding-bottom: 38px - 20px; // Withdraw the invisible padding inside the tabs
	}
}
