@import '../../scss/t.scss';

.FormInput {
	$brand-color: #009de0;
	$brand-dark: #002433;
	position: relative;
	font-size: 16px;
	width: 100%;

	&--Default {
		display: grid;
		grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
		align-items: center;
	}

	&__Label {
		color: $brand-color;

		&--Disabled {
			color: transparentize(#fff, 0.5);
		}

		&--Login {
			transition: transform 150ms ease-in-out, font-size 150ms ease-in-out;
			transform-origin: top left;
			position: absolute;
			top: 13px;
			transform: translateY(0%);
			pointer-events: none;

			&--dirty {
				transform: translateY(-20px);
				font-size: 13px;
			}
		}

		&--Default {
			font-size: 15px;
			line-height: 18px;
			color: $brand-dark;
			font-weight: 700;
		}

		&--Admin {
			transition: transform 150ms ease-in-out, font-size 150ms ease-in-out;
			transform-origin: top left;
			position: absolute;
			top: 13px;
			transform: translateY(0%);
			pointer-events: none;

			&--dirty {
				transform: translateY(-20px);
				font-size: 13px;
			}
		}
	}

	&__Input {
		display: flex;
		justify-content: flex-end;
	}

	&__Error {
		font-size: 14px;
		margin-top: 4px;
		height: 18px;
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		text-align: right;
	}

	&Text {
		outline: none;
		border: none;
		background-color: transparent;
		padding: 3px 0 0px 0;
		margin-top: 10px;
		color: currentColor;
		width: 100%;
		box-sizing: border-box;

		&--Login {
			color: #fff;
			font-size: 15px;
			border-bottom: 1px solid $brand-color;
			width: 100%;
		}

		&--Default {
			height: 40px;
			background-color: #eceff0;
			border-radius: 6px;
			padding-inline: 18px;
			text-align: right;
			display: flex;
			justify-content: flex-end;
			font-size: 15px;
			border: 1px solid transparent;
			transition: border-color 150ms ease-out;

			&:focus,
			&:hover {
				border-color: rgb(0, 36, 51, 0.3);
			}
		}

		&--Admin {
			color: #fff;
			font-size: 15px;
			border-bottom: 1px solid #fff;
			width: 100%;
		}

		&:disabled {
			opacity: 0.5;
			border-bottom-color: transparentize($color: #fff, $amount: 0.5);
		}
	}
}

.FormInputSelect {
	width: 100%;
	background-color: #eceff0;
	border-radius: 6px;
	padding-inline: 18px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	gap: 9px;
	height: 40px;
	transition: border-color 150ms ease-out, transform 150ms ease-out;
	border: 1px solid transparent;

	&:hover {
		border-color: rgb(0, 36, 51, 0.3);
	}

	&:focus {
		outline: transparent;
		border: 1px solid;
		border-color: rgb(0, 36, 51, 0.3);
	}

	&--Admin {
		background-color: transparent;
		border-bottom: 1px solid #fff;
		border-radius: 0px;
		justify-content: space-between;
		padding-inline: 0px;
		height: 45px;

		&:hover {
			border-bottom-color: #fff;
		}
	}

	&__Value {
		overflow: hidden;
		line-height: 50px;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 91%;
		user-select: none; /* Standard syntax */

		&--Admin {
			text-align: left;
			font-size: 15px;
			color: #fff;
			max-width: 100%;
			padding: 13px 0;
		}
	}

	&__Select {
		margin-top: -10px; // Position based on inner icon, not the hover state
		margin-bottom: -10px; // Position based on inner icon, not the hover state
		display: flex;
		align-items: center;
		transition: background-color $t-hover;
		cursor: pointer;
		width: 100%;
		font-size: 15px;

		& .Icon {
			transition: transform 150ms ease-out;
		}

		&:hover,
		&.isOpen {
			background-color: transparentize(#fff, 0.6);
		}

		&.isOpen {
			& .Icon {
				transform: rotateZ(-180deg);
			}
		}

		&--Admin {
			margin-top: 0px;
			margin-bottom: 0px;

			&:hover,
			&.isOpen {
				background-color: transparentize(#fff, 1);
			}
		}

		.Icon {
			position: relative;
			// top: -1px;
			color: #009de0;
		}

		&.isDisabled {
			opacity: 0.5;
			border-bottom-color: transparentize($color: #fff, $amount: 0.5);
		}
	}
}
