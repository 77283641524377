$transitionConfig: 180ms ease-in-out;

.ResetViewContainer {
	display: flex;
	align-items: center;
	color: #ffffff;
	cursor: pointer;
	padding: 6px 8px;
	text-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
	font-family: 'Gotham Rounded A', 'Gotham Rounded B';
	border-radius: 9px;
	position: absolute;
	background-color: rgba(0, 0, 0, 0);
	opacity: 0.7;
	transition: $transitionConfig;
	transition: opacity 0.35s ease-in-out, transform 0.35s ease-in-out;

	.ResetView__Text {
		margin-left: 8px;
		transition: $transitionConfig;
		z-index: 1;
		font-size: 14px;
		font-weight: 400;
	}

	svg {
		fill: white;
		width: 17px;
		height: 20px;
		transition: $transitionConfig;
	}

	&:hover {
		background-color: rgba(62, 108, 126, 0.8);
		opacity: 1;
	}
}

.hide {
	opacity: 0;
	pointer-events: none;
}

.show {
	opacity: 0.7;
}
