.Breadcrumb {
	$breadcrumb-color: rgba(255, 255, 255, 0.3);
	display: flex;
	align-items: center;
	gap: 4px;

	&__Base {
		display: flex;
		align-items: center;
		gap: 6px;
		color: $breadcrumb-color;
		font-weight: 400;
		text-decoration: none;
		cursor: default;

		&--Highlight {
			cursor: pointer;
			color: #fff;

			&:hover > .Breadcrumb__Icon {
				color: #00384f;
				background-color: #fff;
			}

			&:hover .Breadcrumb__Title {
				text-decoration: underline;
			}
		}
	}

	&__Icon {
		transition: color 150ms ease-out, background-color 150ms ease-out;
		width: 30px;
		height: 30px;
		border: 1px solid $breadcrumb-color;
		border-radius: 50%;
		position: relative;

		// The only way to truly center anything...
		& > .Icon {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -55%);
		}

		&--Highlight {
			border-color: #fff;
		}
	}

	&__Title {
		font-size: 13px;
		text-transform: capitalize;
	}

	&__Divider {
		color: $breadcrumb-color;
		font-weight: 400;
		transform: translateY(1px);
	}
}
