.Spinner {
	width: 24px;
	color: #fff;
	animation: rotate 4s infinite linear;
}

@keyframes rotate {
	from {
		transform: rotate(0);
	}
	to {
		transform: rotate(359deg);
	}
}
