$padding: 24px;

.SiteSelector {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;

	&__BackgroundImage {
		flex: auto;
		background-position: center center;
		background-size: cover;
	}

	&__Sidebar {
		flex: none;
		max-width: 500px;
		width: 100%;
		background-color: #002433;
		height: 100%;
		display: flex;
		flex-flow: column;
	}

	&__SiteSelector__SidebarHeader {
		flex: none;
	}

	&__SidebarMain {
		flex: auto;
		padding-top: 5px;
		padding-bottom: $padding;
		padding-left: $padding;
		padding-right: $padding;
		display: flex;
		flex-flow: column;
		gap: 6px;
		overflow-y: auto;
	}
}
