.DescriptionTip {
	&__Title {
		font-size: 16px;
		font-weight: 600;
	}

	&__Content {
		margin-top: 10px;
		font-weight: 400;
	}
}
