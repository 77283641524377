.Card {
	width: 100%;
	height: 100%;
	border-radius: 6px;
	position: relative;

	&--Theme-default {
		background-color: #00384f;
	}

	&--Theme-dark {
		background-color: #003347;
	}

	&--Padding-default {
		padding: 1.5rem 20px;
	}

	&--Padding-small {
		padding: 1rem 20px;
	}

	&--Padding-large {
		padding: 2rem 20px;
	}

	&--Padding-tiny {
		padding: 6px 20px;
	}

	&--Radius-top {
		border-radius: 6px 6px 0 0;
	}
	&--Radius-bottom {
		border-radius: 0 0 6px 6px;
	}
	&--Radius-left {
		border-radius: 6px 0 0 6px;
	}
	&--Radius-right {
		border-radius: 0 6px 6px 0;
	}
	&--Radius-all {
		border-radius: 6px;
	}
}
