.SRSMenuControls {
	&__Menu {
		position: absolute;
		background: white;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
		border-radius: 6px;
		padding: 8px;
		z-index: 100;

		&__Item {
			width: 100%;
			height: 100%;
			&:hover {
				cursor: pointer;
				background: #f6f7f8;
			}
			font-weight: normal;
			font-size: 14px;

			border-bottom: #f6f7f8 1px solid;
			&:last-child {
				border-bottom: none;
			}
		}
	}

	&__DeleteConfirmation {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: transparentize(#002433, 0.3);
		z-index: 1050; // High z-index to ensure it covers other content
		cursor: default; // Default cursor for the modal content

		&__Content {
			background: white;
			border-radius: 20px; // Rounded corners
			z-index: 1051; // Ensure modal is above the overlay
			width: auto; // Width can be adjusted based on content or set to a specific value
			width: 465px;
			height: 277px;
			padding: 20px; // Padding around the content inside the modal
			cursor: default; // Default cursor for the modal content

			&__Title {
				display: flex;
				justify-content: center;
				align-items: center;

				font-size: 25px;
				font-weight: 500;
				margin-bottom: 25px;
				letter-spacing: -2px;
				color: #009de0;

				margin-top: 45px;
			}

			&__Text {
				display: flex;
				justify-content: center;
				align-items: center;

				margin-left: 70px;
				margin-right: 70px;

				font-size: 15px;
				font-weight: 400;
				margin-bottom: 20px;
				text-align: center;
				letter-spacing: -1.13px;
				line-height: 20px;

				margin-bottom: 25px;
			}

			&__Buttons {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 5px;

				margin-top: 20px;
				margin-bottom: 20px;

				&__Cancel {
					width: 150px;
					height: 25px;
					border-radius: 14px;
					border: none;
					font-size: 14px;
					font-weight: 400;
					cursor: pointer;
					transition: 0.3s;
					font-family: 'Gotham Rounded A', 'Gotham Rounded B';
					background-color: white;
					border: 1px solid #009de0;
					color: #009de0;

					&:hover {
						background-color: darken(white, 10%);
					}
				}

				&__Delete {
					width: 150px;
					height: 25px;
					border-radius: 14px;
					border: none;
					font-size: 14px;
					font-weight: 400;
					cursor: pointer;
					transition: 0.3s;
					font-family: 'Gotham Rounded A', 'Gotham Rounded B';
					background-color: #009de0;
					color: white;

					&:hover {
						background-color: darken(#009de0, 10%);
					}
				}
			}
		}
	}
}
