@mixin elipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin scrollbar {
	&::-webkit-scrollbar {
		background-color: transparent;
		width: 8px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgba(255, 255, 255, 0.2);
		border-radius: 3px;
	}

	&::-webkit-scrollbar-track {
		background-color: #002433;
	}
}

.u-full-height > div {
	height: 100%;
}
