.TrendsContent {
	padding: 23px;

	&__Calendar {
		display: flex;
		max-width: 200px;
		margin-left: auto;
		justify-content: flex-end;
		margin-bottom: 16px;
	}
	&__Content {
		display: grid;
		grid-template-columns: minmax(0, 1fr);
		gap: 1em;

		& > .Card {
			display: flex;
			flex-direction: column;
		}

		&--Fullscreen {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}
	}
	&__CardButtons {
		display: inline-flex;
		gap: 6px;

		& > .Button {
			max-width: 119px;
		}
	}

	&__ButtonContent {
		display: flex;
		align-items: center;
		gap: 4px;

		& > p {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&__MoreButton {
		& > .Button {
			padding: 0px;
			width: 30px;
		}
	}

	&__PopMenu {
		& > .PopMenu__Item {
			cursor: default;

			&:hover {
				background-color: transparent;
			}
		}
	}
}
