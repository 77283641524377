.ToolTip {
	background-color: #00384f;
	border-radius: 6px;
	padding: 10px 11px;
	font-weight: 400;
	color: transparentize(#fff, 0.2);
	max-width: 25em;
	box-shadow: 0 2px 15px 0 transparentize(#000, 0.5);
	font-size: 14px;
	pointer-events: none;
	z-index: 2000;

	// make text wrap
	white-space: normal;
	word-wrap: break-word;

}

.ToolTipWrapper {
	position: relative;
	z-index: 2000;
}
