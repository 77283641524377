.ScatterPlotChart {
	padding-bottom: 1rem;
	position: relative;
	padding-top: 2.2rem;
	aspect-ratio: 1/1;

	&__DateRange {
		position: absolute;
		left: 0;
		bottom: 0;
	}

	& .apexcharts-gridlines-horizontal {
		& line {
			stroke-width: 1px;
			height: 1px;
		}
	}

	& .apexcharts-tooltip.apexcharts-theme-light {
		border: none;
		box-shadow: none;
	}

	& .apexcharts-toolbar {
		z-index: 1;
		gap: 4px;
	}

	& .apexcharts-menu-icon {
		transform: scale(1);
		display: flex;
		align-items: center;
		justify-content: center;

		& > div {
			transform: translateY(1px);
		}

		&:hover svg {
			color: #fff;
		}
	}

	& .apexcharts-reset-icon {
		transform: scale(1);
		margin-left: 0;
		display: flex;
		align-items: center;
		justify-content: center;

		& > div {
			transform: translateY(2px);
		}

		&:hover svg {
			color: #fff;
		}
	}

	& .apexcharts-zoomin-icon {
		transform: scale(1);
		display: flex;
		align-items: center;
		justify-content: center;

		& > div {
			transform: translateY(2px);
		}

		&:hover svg {
			color: #fff;
		}
	}
	& .apexcharts-zoomout-icon {
		transform: scale(1);
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0;

		&:hover svg {
			color: #fff;
		}
	}

	& .apexcharts-zoom-icon {
		transform: scale(1);
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0;
		display: none;

		& > div {
			transform: translateY(2px);
		}

		&:hover svg {
			color: #fff;
		}
	}

	& .apexcharts-menu {
		background-color: #002838;
		color: #fff;
		border: none;
		display: flex;
		flex-flow: column;
		gap: 1px;
		padding: 0;
		margin: 0;

		& > .apexcharts-menu-item {
			border-bottom: 1px solid rgba(255, 255, 255, 0.25);
			cursor: pointer;

			&:last-child {
				border-bottom: none;
			}

			&:hover {
				background-color: lighten(#002838, 10%);
			}
		}
	}
}
