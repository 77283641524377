@import '../../Core//scss/u.scss';

.ResultBar {
	&__BarTrack {
		height: 6px;
		border-radius: 2px;
		background-color: transparentize(#fff, 0.85);
		overflow: hidden;
		position: relative;
	}

	&__Bar {
		height: 100%;
	}

	&__Text {
		display: flex;
		margin-top: 4px;
		align-items: baseline;
	}

	&__Label {
		@include elipsis();

		color: transparentize(#fff, 0.6);
		line-height: 1;
		margin-right: 5px;
		font-size: 9px;
	}

	&__Value {
		line-height: 1;
		color: transparentize(#fff, 0.3);
		margin-left: auto;
		font-size: 13px;
	}
}
