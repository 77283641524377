@import '../../Core/scss/t.scss';

$backgroundColor: #007eb4;
.ExplorerNavigation {
	display: flex;
	text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);

	&__Button {
		background-color: $backgroundColor;
		transition: background-color $t-hover, color $t-hover;
		color: transparentize(#fff, 0.2);

		&:hover {
			background-color: mix($backgroundColor, #fff, 90%);
			color: #fff;
		}
	}

	&__Button:first-child {
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px;
	}

	&__Button:last-child {
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
	}

	&__Button + &__Button {
		border-left: 0.5px solid #005a81;
	}

	&__AnalysisExplorerButton {
		background-color: #009de0;
		transition: background-color $t-hover, color $t-hover;
		border-left: 0.5px solid #005a81;
		display: flex;
		width: 131px;
		align-items: center;
		justify-content: center;
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
		transition: $backgroundColor $t-hover, color $t-hover, width $t-hover,
			display $t-hover;

		&--Active {
			background-color: #234a6d;
			width: 40px;
			.Icon {
				color: #ffffff6c;
				text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
				margin-bottom: 3px;
			}

			.IconButton__Text {
				color: #ffffff6c;
				font-weight: 400;
				text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
				display: none;
			}

			&:hover {
				background-color: mix(#234a6d, #fff, 90%);
				color: #fff;
				cursor: pointer;
			}
		}

		&--NoActive {
			.Icon {
				color: #fff;
				text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
				margin-bottom: 3px;
			}

			.IconButton__Text {
				color: #fff;
				font-weight: 400;
				text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
			}

			&:hover {
				background-color: mix(#009de0, #fff, 90%);
				color: #fff;
				cursor: pointer;
			}
		}
	}
}
