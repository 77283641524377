@use 'sass:math';

@import '../../Core/scss/t.scss';

.SectionResultsTable {
	&__NullScreen {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__NullScreenButton {
		$height: 40px;

		height: $height;
		padding-left: 12px;
		padding-right: 16px;
		border-radius: math.div($height, 2);
		display: flex;
		align-items: center;
		background-color: transparentize(#fff, 0.9);
		cursor: pointer;
		transition: background-color $t-hover;

		&:hover {
			background-color: #000;
		}
	}
}
