@import '../../../Core/scss/t.scss';
@import '../../../Core/scss/u.scss';

$backgroundColor: #002532;
$borderRadius: 6px;
$scrollbarButtonColor: transparentize(#fff, 0.8);
$scrollbarTrackColor: #002433;
$scrollbarWidth: 14px;
$cellPaddingX: 20px;

.ResultsTable {
	// FF styles
	scrollbar-color: $scrollbarButtonColor $scrollbarTrackColor;
	scrollbar-width: $scrollbarWidth;

	// Chrome styles
	&::-webkit-scrollbar {
		width: $scrollbarWidth;
		height: $scrollbarWidth;
	}

	&::-webkit-scrollbar-track,
	&::-webkit-scrollbar-corner {
		background-color: $scrollbarTrackColor;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $scrollbarButtonColor;

		&:hover {
			background-color: transparentize(#fff, 0.7);
		}
	}

	.DataTable__SelectedDivider {
		margin-top: 12px;
		margin-bottom: 12px;
		height: 1px;
		background-color: rgba(255, 255, 255, 0.2);
	}
}

.ResultsTableHeaderColumn {
	height: 45px;
	display: flex;
	flex-flow: column;
	justify-content: center;
	background-color: $backgroundColor;
	background-clip: padding-box;
	padding-left: $cellPaddingX;
	padding-right: $cellPaddingX;
	color: transparentize(#fff, 0.5);
	transition: background-color $t-hover, border $t-hover ease-in-out;
	user-select: none;
	margin-bottom: 1px;
	margin-top: 7px;

	&--AddButton {
		position: absolute;
		right: 25px;
		top: 12px;
		display: inline-block;
		z-index: 10;
		border: #009de0 1px solid;
		border-radius: 50px;
		padding-top: 7px;
		padding-bottom: 7px;
		white-space: nowrap;
		overflow: hidden;
		padding-left: 8px;
		padding-right: 4px;
		transition: padding-right 100ms, opacity 180ms, color 180ms;
		background-color: #00202e;
		filter: drop-shadow(0px 0px 8px #000000);

		&--show {
			opacity: 1;
			cursor: pointer;

			&--text {
				opacity: 0;
				display: inline-block;
				max-width: 0;
				transition: opacity $t-hover, max-width $t-hover;
			}

			&:hover &--text {
				opacity: 1;
				max-width: 100px;
				color: white;
			}

			&:hover .Icon {
				&__element {
					color: white;
					fill: white;
				}
			}

			&:hover {
				padding-right: 10px;
				padding-left: 10x;
				background-color: #009de0;
			}
		}

		&--hide {
			opacity: 0;
			cursor: default;
			z-index: 0;
		}
	}

	&.isHighlighted {
		border-bottom: #009de0 2px solid;
		border-top-left-radius: 7px;
		border-top-right-radius: 7px;
		background: rgb(0, 56, 79);
		background: linear-gradient(180deg, rgba(0, 56, 79, 1) 35%, #00202e 100%);
	}

	&__TitleWrapper {
		display: flex;
		position: relative;
		align-items: center;
		transition: color $t-hover;

		.ResultsTable__Caret {
			position: absolute;
			top: 3px;
			right: calc(100% + 5px);
			opacity: 0;
			transition: opacity $t-hover;
		}

		&.isSorted {
			color: #fff;

			.ResultsTable__Caret {
				opacity: 1;
			}
		}
	}

	&__HoverEffect {
		&:hover {
			&:first-child {
				background-color: #002532;
				cursor: default;
			}
			border-top-left-radius: 7px;
			border-top-right-radius: 7px;
			background: rgb(0, 74, 104);
			cursor: pointer;

			&:last-child {
				background-color: #002532;
				cursor: default;
			}
			&.isHighlighted {
				border-bottom: #009de0 2px solid;
				border-top-left-radius: 7px;
				border-top-right-radius: 7px;
				background: rgb(0, 56, 79);
				background: linear-gradient(
					180deg,
					rgb(0, 77, 107) 35%,
					rgba(0, 56, 79, 0) 100%
				);
			}
		}
	}

	&__DragEffect {
		border-top-left-radius: 7px;
		border-top-right-radius: 7px;
		background: rgb(0, 74, 104);
		cursor: pointer;
	}

	&__DragOver {
		border-top-left-radius: 7px;
		border-top-right-radius: 7px;
		background: rgb(0, 96, 134);
		background: linear-gradient(
			180deg,
			rgb(0, 96, 134) 35%,
			rgb(0, 96, 134) 100%
		);

		&.isHighlighted {
			border-bottom: #009de0 2px solid;
			border-top-left-radius: 7px;
			border-top-right-radius: 7px;
			background: rgb(0, 96, 134);
			background: linear-gradient(
				180deg,
				rgb(0, 96, 134) 35%,
				rgb(0, 96, 134) 100%
			);
		}
	}

	&__MenuIcon {
		margin-top: -10px; // Position based on inner icon, not the hover state
		margin-bottom: -10px; // Position based on inner icon, not the hover state
		display: flex;
		align-items: center;
		justify-content: center;
		width: 27px;
		height: 27px;
		border-radius: 50%;
		flex: none;
		margin-left: auto;
		transition: background-color $t-hover;
		cursor: pointer;

		&.isOpen {
			background-color: transparentize(#fff, 0.6);
		}
		.Icon {
			position: relative;
			top: -1px;
		}
	}
	&__noDrag {
		&:hover {
			background-color: transparentize(#fff, 0.6);
		}
	}

	&__Title {
		@include elipsis;

		font-size: 14px;
		min-width: 0;

		&__Subscript {
			margin-left: 1px;
			font-size: 9px;
			vertical-align: sub;
			padding-right: 1px;
		}
	}

	&__Subtitle {
		font-size: 9px;
	}
}

.ResultsTableRow {
	// Spacing between rows
	& + & {
		margin-top: 2px;
	}

	&__ParentCell,
	&__ChildCell {
		display: flex;
		flex-flow: column;
		justify-content: center;
		padding-left: $cellPaddingX;
		padding-right: $cellPaddingX;

		&.isHighlighted {
			background-color: #366d82;
		}
	}

	&__ParentCell {
		$backgroundColor: #00384f;
		$borderHighlight: 1px solid rgba(255, 255, 255, 0.7);

		height: 61px;
		background-color: $backgroundColor;
		transition: background-color $t-hover;
		cursor: pointer;

		&.isSelected {
			// background-color: #04445d;
			background-color: #26576a;
		}

		&.isUnfolded {
			margin-bottom: 1px;
		}

		&.isFirstColumn {
			border-top-left-radius: $borderRadius;
		}
		&.isFirstColumn:not(.isUnfolded) {
			border-bottom-left-radius: $borderRadius;
		}

		&.isLastColumn {
			border-top-right-radius: $borderRadius;
		}
		&.isLastColumn:not(.isUnfolded) {
			border-bottom-right-radius: $borderRadius;
		}
	}

	&__ChildCell {
		background-color: #003446;
		height: 50px;

		&.isLastChild.isFirstColumn {
			border-bottom-left-radius: $borderRadius;
		}

		&.isLastChild.isLastColumn {
			border-bottom-right-radius: $borderRadius;
		}
	}

	// The first column with name and type
	&__Info {
		border-right: 1px solid $backgroundColor; // Spacing after row
	}

	&__InfoNameWrapper {
		position: relative; // Position caret

		.ResultsTable__Caret {
			$clickPaddingX: 10px;
			$clickPaddingY: 6px;

			position: absolute;
			top: 3px;
			right: calc(100% + 4px);
			color: transparentize(#fff, 0.3);
			padding: $clickPaddingY $clickPaddingX;
			margin: (-$clickPaddingY) (-$clickPaddingX);
			transition: color $t-hover;
			cursor: pointer;

			&:hover {
				color: #fff;
			}
		}
	}

	&__InfoName {
		font-size: 14px;
		color: #fff;
		white-space: nowrap; // Elipsis
		overflow: hidden; // Elipsis
		text-overflow: ellipsis; // Elipsis
	}

	&__InfoType {
		margin-top: 1px;
		font-size: 9px;
		color: transparentize(#fff, 0.6);
	}

	&__ChooserCell {
		margin-left: 2px;
		background-color: transparentize(#00384f, 0.4);

		&.isFirstRow {
			border-top-left-radius: $borderRadius;
			border-top-right-radius: $borderRadius;
		}

		&.isLastRow {
			border-bottom-left-radius: $borderRadius;
			border-bottom-right-radius: $borderRadius;
		}
	}
}

.ResultsTableLoading {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 100;
	background-color: transparentize($backgroundColor, 0.5);
}
