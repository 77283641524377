.LegendInformationContainer {
	position: fixed;
	bottom: 0px;
	left: 140px;
	transform: translateY(10px);
	opacity: 0;
	transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out,
		display 0.3s ease-in-out;
	min-height: 80px;

	&.show {
		opacity: 1;
		transform: translateY(0);
	}

	&.hide {
		opacity: 0;
		transform: translateY(10px);
	}

	.LegendInformation {
		background: rgba(255, 255, 255, 0.6);
		box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.15);
		border: 1px solid rgba(255, 255, 255, 0.6);
		border-radius: 10px;
		width: 250px;
		height: 10px;
		display: flex;
		pointer-events: none;

		&__text-top,
		&__text-bottom {
			display: flex;
			justify-content: space-between;
			width: 100%;
			font-style: normal;
			font-weight: 325;
			font-size: 11px;
			line-height: 12px;
			color: #fff;
			text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
			/* identical to box height, or 109% */
			letter-spacing: 0.54px;
			font-family: 'Gotham Rounded A', 'Gotham Rounded B', Arial, Helvetica,
				sans-serif;
		}

		&__text-top,
		&__text-bottom,
		&__text-topTitle,
		&__text-bottomTitle {
			opacity: 0;
			transform: translateY(5px);
			transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
		}

		&__text-top.shown,
		&__text-bottom.shown,
		&__text-topTitle.shown,
		&__text-bottomTitle.shown {
			opacity: 1;
			transform: translateY(0);
		}

		&__Subscript {
			margin-left: 1px;
			font-size: xx-small;
			vertical-align: sub;
			padding-right: 1px;
		}

		&__spanGreenYellow,
		&__spanYellowOrange,
		&__spanOrangeRed {
			width: 25%;
			text-align: center;
		}

		&__spanGreenYellow {
			margin-right: 30px;
		}

		&__spanYellowOrange {
			margin-left: -62.5px;
			margin-right: 29.5px;
		}

		&__spanOrangeRed {
			margin-left: -62.5px;
			margin-right: 31.5px;
		}

		&__text-top {
			margin-bottom: -6px;
		}

		&__text-bottom {
			margin-top: 6px;
		}

		&__text-topTitle,
		&__text-bottomTitle {
			display: flex;
			gap: 3px;
			justify-content: first baseline;
			position: relative;
			left: 270px; /* Adjust this value to place the titles at the desired position */
			bottom: 10px;
			font-size: 11px;
			line-height: 12px;
			color: #fff;
			font-style: bold;
			text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
			/* identical to box height, or 109% */
			letter-spacing: 0.7px;
			font-family: 'Gotham Rounded A', 'Gotham Rounded B', Arial, Helvetica,
				sans-serif;
		}

		&__textTitle {
			font-weight: 325;
			font-style: normal;
		}

		&__text-topTitle {
			top: -6px;
		}

		&__text-bottomTitle {
			top: -12px;
		}

		&__maxValue {
			position: absolute;
			left: 75%;
			text-align: right;
			width: 25%;
		}

		&__minValue {
			position: absolute;
		}

		&__green,
		&__yellow,
		&__orange,
		&__red {
			flex: 1;
			height: 8px;
		}

		&__green {
			background-color: #06bb75;
			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;
		}

		&__yellow {
			background-color: #ffc300;
		}

		&__orange {
			background-color: #f57800;
		}

		&__red {
			background-color: #e00039;
			border-top-right-radius: 10px;
			border-bottom-right-radius: 10px;
		}
	}
}
