$range-color: #00567a;
.CalendarContainer {
	display: grid;
	grid-template-columns: 198px 1fr;
	grid-template-rows: auto;
	border-radius: 6px;
	transform: translateX(-60%);
	overflow: hidden;
	box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.5);
	user-select: none; // Disable highlights when double clicking

	&__QuickAccess {
		background-color: #003145;
		border-right: 1px solid rgba(212, 224, 230, 0.15);
	}

	&__Option {
		padding: 13px 25px;
		color: rgba(255, 255, 255, 0.8);
		font-size: 14px;
		line-height: 17px;
		border-bottom: 1px solid rgba(212, 224, 230, 0.15);

		&:hover {
			background-color: transparentize(#fff, 0.9);
		}
	}

	& > .react-datepicker {
		width: 100%;
		font-size: 14px;
		background-color: #00384f;
		border: none;
		border-radius: 0px;

		& .react-datepicker__month-container {
			float: none;
		}

		& .react-datepicker__month {
			margin: 0;
			padding-inline: 45px;
			padding-bottom: 45px;
		}

		& .react-datepicker__day {
			opacity: 1;
			width: 31px;
			height: 31px;
			border-radius: 50%;
			border: 1px solid rgba(255, 255, 255, 0.25);
			color: #fff;
			margin: 2px;
			line-height: 29px;

			&:hover {
				cursor: pointer;
				background-color: #009de0;
			}
		}

		& .react-datepicker__day--selected {
			background-color: #009de0;
			z-index: 4;
			transform: translateZ(2px);
		}

		/* IN RANGE (SELECTED) */
		& .react-datepicker__day--in-range {
			border: 0px solid #009de0;
			// border-radius: 0px;
			background-color: transparent;
			position: relative;
			transform-style: preserve-3d;

			&:last-child {
				&::after {
					transform: translateZ(-1px) translateX(-5px);
				}
			}

			&::after {
				content: '';
				position: absolute;
				inset: 0px;
				width: calc(100% + 5px);
				background-color: $range-color;
				transform: translateZ(-1px);
			}
		}

		& .react-datepicker__day--keyboard-selected {
			background-color: transparent;
		}

		& .react-datepicker__day--range-start {
			// border-right: none;
			background-color: #009de0;
			border-radius: 50%;
			z-index: 4;
			transform-style: preserve-3d;

			&::after {
				content: '';
				position: absolute;
				inset: 0px;
				width: 19px;
				background-color: $range-color;
				// background-color: transparentize($color: #fff, $amount: 0.6);
				transform: translateX(19px) translateZ(-1px);
				z-index: 1;
			}

			&:last-child {
				&::after {
					transform: translateZ(-1px) translateX(12px);
				}
			}
		}

		& .react-datepicker__day--range-end {
			// border-left: none;
			background-color: #009de0;
			border-radius: 50%;
			transform-style: preserve-3d;
			z-index: 4;

			&::after {
				content: '';
				position: absolute;
				inset: 0px;
				width: 19px;
				background-color: $range-color;
				transform: translateX(-5px) translateZ(-1px);
				z-index: 1;
			}

			&:first-child::after {
				content: '';
				position: absolute;
				inset: 0px;
				width: 19px;
				background-color: $range-color;
				transform: translateX(0px) translateZ(-1px);
				z-index: 1;
			}

			&:hover {
				background-color: #009de0 !important;
			}
		}

		/* IN SELECTING RANGE */
		& .react-datepicker__day--in-selecting-range {
			background-color: #009de0;
			border-color: #009de0;

			&:hover {
				background-color: transparent;
			}
		}

		& .react-datepicker__day--outside-month {
			color: rgba(255, 255, 255, 0.25);
			&:hover {
				background-color: transparent;
				cursor: default;
			}
		}

		& .react-datepicker__day--disabled {
			color: rgba(255, 255, 255, 0.25);
			cursor: default;

			&:hover {
				background-color: transparent;
				cursor: default;
			}
		}
	}
}
