@import '../../scss/u.scss';
@import '../../scss/t.scss';

.PopMenu {
	display: flex;
	flex-flow: column;
	border-radius: 6px;
	font-weight: 400;
	max-width: 28em;
	box-shadow: 0 2px 15px 0 transparentize(#000, 0.5);
	font-size: 14px;
	z-index: 1000;
	min-width: 200px;
	overflow: hidden;
	gap: 1px;

	// Use generated content as separator to avoid weird color changes in borders on hover
	// Due to the separator having transparent bg
	&__Item + &__Item:before {
		position: absolute;
		bottom: 100%;
		left: 0;
		right: 0;
		content: '';
		height: 1px;
		background-color: transparentize(#d4e0e6, 0.85);
	}

	&__Item:hover {
		background-color: transparentize(#fff, 0.9);
	}

	&__Item {
		display: flex;
		align-items: center;
		height: 40px;
		transition: background-color $t-hover;
		cursor: pointer;
		position: relative;

		&--Theme-Default {
			background-color: #eceff0;

			&:hover {
				background-color: #fff;
			}

			& .PopMenu__ItemLabel {
				color: #002433;
			}
		}

		&:focus {
			outline: transparent;
			border: 1px solid;
			border-color: rgb(0, 36, 51, 0.3);
			border-radius: 5px;
		}
	}

	&__Item.hasIcon {
		.PopMenu__ItemLabel {
			padding-left: 0;
		}
	}

	&__ItemLabel {
		@include elipsis();

		font-weight: inherit;
		padding-left: 11px;
		padding-right: 11px;
	}

	&__ItemIcon {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
	}
}

.PopMenuWrapper {
	position: relative;
	z-index: 1000;
}

// Variant with icons
.PopMenu.hasIcon {
	.PopMenu {
		&__Item.hasIcon .PopMenu__ItemLabel {
			padding-left: 0;
		}

		&__ItemLabel {
			padding-left: 40px;
		}
	}
}

// Themes
.PopMenu--Theme-Default {
	background-color: #d4e0e6;
	color: #002433;
	box-shadow: 0 2px 15px 0 transparentize(#000, 0.8);

	.PopMenu {
		&__Item {
			background-color: #eceff0;

			&:hover {
				background-color: #fff;
			}

			&.isSelected {
				background-color: #dae0e2;
				color: transparentize(#002433, 0.3);
			}
		}
	}
}

.PopMenu--Theme-Dark {
	background-color: #00384f;
	color: transparentize(#fff, 0.2);

	.PopMenu {
		&__Item {
			&:hover {
				background-color: transparentize(#fff, 0.9);
			}

			&.isSelected {
				color: transparentize(#fff, 0.7);
			}
		}
	}
}

.PopMenu--Theme-Admin {
	background-color: #00384f;
	color: transparentize(#fff, 0.2);
	width: 100%;
	max-width: 424px;

	.PopMenu {
		&__Item {
			&:hover {
				background-color: transparentize(#fff, 0.9);
			}

			&.isSelected {
				color: transparentize(#002433, 0.7);
				color: transparentize(#fff, 0.7);
			}
		}
	}
}
