.UserCard {
	display: flex;

	&__Thumbnail {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		border-radius: 9px;
		border: 2px solid #00e075;
		background-color: #1b5275;
		font-size: 12px;
		font-weight: bold;
		color: #fff;
		line-height: 0;
	}

	&__Details {
		display: flex;
		flex-flow: column;
		justify-content: center;
		margin-left: 10px;
	}

	&__Name {
		color: #fff;
		font-size: 13px;
		font-weight: bold;
	}

	&__AccountName {
		margin-top: 1px;
		color: rgba(255, 255, 255, 0.4);
		font-size: 13px;
		font-weight: 500;
	}
}
