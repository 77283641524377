.DataTable {
	overflow: auto; // Activate scrollbars in both directions
}

.DataTableContent {
	opacity: 1;
	filter: blur(0);
	transition: opacity 150ms ease-in-out, filter 150ms ease-in-out;

	&__isDragging {
		// To force GPU acceleration - Needed to minimize performance degradation when a lot of data is loaded in the table
		// Also helps with bug in Safari: https://stackoverflow.com/questions/31713468/css-blur-filter-performance
		transform: translate3d(1, 0, 0);
		opacity: 0.6;
		filter: blur(2.5px);
	}
}
