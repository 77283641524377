@import '../../scss/t.scss';

.TitleBar {
	position: absolute;
	height: 40px;
	border-radius: 20px;
	display: inline-flex;
	align-items: center;
	transition: background-color $t-hover;
	margin-right: 15px;
	width: max-content;

	&__Title {
		padding-right: 25px;
		padding-left: 25px;
		font-size: 14px;
		font-weight: 400;
	}

	&__Divider {
		height: 100%;
		width: 1px;
	}

	&__Icon {
		width: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative; // Optical centering
		left: 3px; // Optical centering
	}
}

.TitleBar.hasIcon {
	.TitleBar {
		&__Title {
			padding-left: 13px;
		}
	}
}

.TitleBar--Theme-Default {
	background-color: #ddd;

	.TitleBar {
		&__Divider {
			background-color: #ccc;
		}
	}

	&.TitleBar.isActive {
		background-color: #fff;
	}
}

.TitleBar--Theme-Overlay {
	background-color: transparentize(#3e6c7e, 0.75);
	color: #fff;

	.TitleBar {
		&__Divider {
			background-color: rgba(255, 255, 255, 0.2);
		}
	}

	&.TitleBar.isActive {
		background-color: transparentize(#3e6c7e, 0.2);
	}
}
