.Fullscreener {
	display: flex;
	flex-direction: row;
	height: 100%;
	width: 100%;

	& > *:first-child {
		flex: 1;
	}

	& > .Dashboard__ShowQuickReference {
		flex: 1.5;
	}
	& > .Trends__ShowQuickReference {
		flex: 1.5;
	}

	&--Fullscreen {
		& > *:first-child {
			flex: 0;
		}

		& > *:last-child {
			flex: 1;
		}
	}

	&--NoRightElement {
		& > *:first-child {
			flex: 1;
		}

		& > *:last-child {
			flex: 0;
		}
	}
}
