.Modal {
	&__Title {
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
	}

	&__Footer {
		&__Buttons {
			display: flex;
			justify-content: flex-end;
			gap: 15px;
		}
	}
}

.Button {
	font-family: 'Gotham Rounded A', ' Gotham Rounded B', Arial, Helvetica,
		sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	text-align: center;
	border-radius: 14px;
	width: 90px; // Adjust width as needed
	height: 32px; // Adjust height as needed
	padding: 10px;
	cursor: pointer;
	transition: background-color 0.3s;
	border-radius: 25px; // Make the buttons rounded

	&--Primary {
		background-color: #009de0;
		color: #ffffff;
		border: 2px solid #009de0;

		&:hover {
			background-color: darken(#009de0, 5%);
			border: darken(#009de0, 5%);
		}
	}

	&--Default {
		background-color: #ffffff;
		color: #009de0;
		margin-right: 10px;
		border: 2px solid #009de0;

		&:hover {
			background-color: darken(#ffffff, 5%);
			border-color: darken(#009de0, 5%);
		}
	}

	&:focus {
		box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
	}
}

.ExplorerSettings__FormContainer {
	margin-left: 40px;
	margin-right: 40px;

	.FormInput__Label {
		position: relative; // Add a relative position to FormInput
		padding-bottom: 17px; // Add some padding to the bottom of each FormInput
		padding-top: 17px;
	}

	.FormInput {
		&::after {
			content: '';
			position: absolute;
			left: -40px; // Extend the line to the left by the same amount as the margin
			right: -40px; // Extend the line to the right by the same amount as the margin
			bottom: 0;

			border-bottom: 1px solid #d4e0e6; // Add a line between each FormInput
		}

		&Text {
			margin-top: 0px;
		}
	}

	.FormInput:last-child {
		&::after {
			border-bottom: none; // Remove the line from the last FormInput
		}
	}
}

.PopMenu {
	&__Item {
		width: 260px;
	}
}
