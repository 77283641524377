@import '../Core/scss/t.scss';

$padding: 33px;

.SiteControl {
	padding-top: 24px;

	&__Name {
		color: #009de0;
		font-size: 14px;
		font-weight: bold;
		padding-left: $padding;
	}

	&__Sites {
		margin-top: 13px;
		display: flex;
		flex-flow: column;
		gap: 2px;
	}
}

.SiteControlButton {
	display: flex;
	align-items: center;
	height: 85px;
	padding-left: $padding;
	padding-right: $padding;
	border-radius: 6px;
	background-color: #00384f;
	cursor: pointer;
	transition: background-color $t-hover;

	&:hover {
		background-color: #0a455c;
	}

	&__Text {
		color: #ffffff;
		font-size: 16px;
		font-weight: bold;
		flex: auto;
	}

	&__Caret {
		color: #009de0;
		flex: none;
	}
}
