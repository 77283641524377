.ChartWarning {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 16px;

	&__Message {
		padding: 0.35rem 0;
		color: #fff;
		font-size: 11px;
		font-weight: 400;
		display: flex;
		white-space: nowrap;

		& > p {
			line-height: 14px;
			color: transparentize($color: #fff, $amount: 0.6);

			& > b {
				font-weight: 700;
			}
		}
	}
}
