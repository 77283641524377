@import '../Core/scss/t.scss';

.UserHeader {
	padding-left: 54px;
	padding-right: 54px;
	display: flex;
	align-items: center;
	height: 140px;
	background-color: #002433;
	position: relative;

	// Shadow
	&:after {
		content: '';
		top: 100%;
		left: 0;
		right: 0;
		height: 14px;
		position: absolute;
		pointer-events: none;
		z-index: 3;
		background: linear-gradient(rgba(0, 0, 0, 0.4), transparent);
	}

	&__Main {
		flex: auto;
	}

	&__Controls {
		flex: none;
		display: flex;
		align-items: center;
		height: 40px;
		border-left: 1px solid rgba(255, 255, 255, 0.25);
		margin-right: -8px; // Adjust for optical right alignment
	}

	&__Control {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		color: rgba(255, 255, 255, 0.4);
		cursor: pointer;
		transition: color $t-hover;

		&:hover {
			color: #fff;
		}
	}
}
