@import '../../scss/t.scss';

.MenuButton {
	$normalColor: transparentize(#fff, 0.4);
	$hoverColor: transparentize(#fff, 0.2);
	$activeColor: #009de0;

	width: 50px;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: $normalColor;
	position: relative;
	transition: color $t-hover;
	cursor: pointer;

	&:after {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		border: 2px solid currentColor;
		border-radius: 9px;
		content: '';
		opacity: 0;
		transform: scale(0.8);
		transition: transform $t-hover, opacity $t-hover;
	}

	// Hover state
	&:not(.is-selected):not(.is-disabled):hover:after {
		transform: scale(1);
		opacity: 0.4;
	}

	&:not(.is-selected):not(.is-disabled):hover {
		color: $hoverColor;
	}

	&.is-disabled {
		cursor: default;
	}

	// Selected state
	&.is-selected {
		color: $activeColor;

		&:after {
			transform: scale(1);
			opacity: 1;
		}
	}
}
