.AdminRemoveUserModal {
	padding: 20px;
	text-align: center;


	&__warning {
		margin-top: 10px;
		font-style: italic;
		font-weight: 300;
	}
}

.AdminModalActions {
	display: flex;
	gap: 10px;
}
